<template>
  <v-container>
    <LogoHeader></LogoHeader>
    <v-row class="justify-center d-flex mt-2">
      <v-col lg="4" md="7" class="mx-auto">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-padding text-center">
            <h5 class="text-h5 text-typo font-weight-bold">Ustaw nowe hasło</h5>
          </div>
          
          <div class="card-padding">
            <p
              v-if="setPasswordError"
              class="text-sm font-weight-bold text-red mb-1 text-center"
            >
              {{ setPasswordError }}
            </p>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              v-model="password"
              :type="'password'"
              placeholder="Hasło"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
            >
            </v-text-field>
            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              :type="'password'"
              v-model="password_confirmation"
              placeholder="Potwierdź hasło"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
            >
            </v-text-field>
            <vue-recaptcha
              ref="captcha"
              :sitekey="$captchaSitekey"
              @verify="setPassword"
              :loadRecaptchaScript="true"
            >
              <button style="display: none"></button>
            </vue-recaptcha>
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                bg-gradient-default
                py-2
                px-6
                me-2
                mt-6
                mb-2
                w-100
              "
              color="#5e72e4"
              small
              @click="validateSetPassword"
              :loading="setPasswordLoading"
              >Ustaw hasło</v-btn
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
    <Footer></Footer>
  </v-container>
</template>
<script>
import qs from "qs";
import VueRecaptcha from "vue-recaptcha";
import LogoHeader from "@/components/util/LogoHeader.vue"
import Footer from "@/components/Footer.vue";
export default {
  name: "NewPassword",
  data() {
    return {
      password: "",
      password_confirmation: "",
      setPasswordError: "",
      setPasswordLoading: false,
    };
  },
  components: {
    VueRecaptcha,
    LogoHeader,
    Footer
  },
  methods: {
    setError(error) {
      this.setPasswordError = error;
    },
    setLoading(val) {
      this.setPasswordLoading = val;
    },
    async fetchUser() {
      try {
        await this.$store.dispatch("fetchUser");
        if (this.$store.getters["hasUser"]) {
          this.$router.push("/dashboard");
        } else {
          this.setError("Błędne dane");
        }
        this.setLoading(false);
      } catch (error) {
        this.setError("Błędne dane");
        this.setLoading(false);
      }
    },
    validateSetPassword() {
      if (
        this.password.trim().length == 0 ||
        this.password_confirmation.trim().length == 0
      ) {
        return this.setError("Podaj dane do rejestracji");
      } else if (this.password !== this.password_confirmation){
          return this.setError("Hasła się różnią");
      } else if (this.password.length<8){
          return this.setError("Hasło musi mieć min. 8 znaków");
      } else {
        this.$refs.captcha.execute();
      }
    },
    setPassword(captcha_token) {
      this.$refs.captcha.reset();
      this.setLoading(true);
      this.$axios({
        method: "post",
        url: "/api/auth/setpassword",
        data: qs.stringify({
          token:this.$route.params.token,
          captcha: captcha_token,
          password: this.password,
          password_confirmation: this.password_confirmation,
        }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.data.success) {
            window.setTimeout(() => {
              this.fetchUser();
            }, 700);
          } else {
            this.setError("Błędne dane!");
            this.setLoading(false);
          }
        })
        .catch(() => {
          this.setLoading(false);
          this.setError("Błędne dane!");
        });
    }
  },
};
</script>
<style>

.captcha-mobile .grecaptcha-badge {
  visibility: hidden;
}
</style>
